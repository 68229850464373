import Header from "components/header/Header";
import Testimonials from "components/Testimonials";
import Introduction from "./components/Introduction";
import Footer from "components/footer";
import BookDemo from "./components/BookDemo";
import SlideUpOnScroll from "components/SlideUpOnScroll";
import "./desktop.scss";

const FeatureMemberPortal = () => {
  return (
    <>
      <div className="app__wrapper">
        <img
          src="/images/header/bg-feature-header.png"
          alt="background"
          className="app__wrapper--image"
        />
        <Header />
        <div className="FeatureMemberPortal-header-wrapper">
          <div className="FeatureMemberPortal-header-title">
            Empowering Members Portal
          </div>
          <div className="FeatureMemberPortal-header-description">
            Your gateway to a seamless swim school experience
          </div>
        </div>
      </div>
      <Introduction />
      <Testimonials />
      <SlideUpOnScroll>
        <BookDemo />
      </SlideUpOnScroll>
      <Footer />
    </>
  );
};

export default FeatureMemberPortal;
