import Header from "components/header/Header";
import BookDemo from "pages/home/components/BookDemo";
import { lazy } from "react";
const SocialProof = lazy(() => import('pages/home/components/SocialProof'));
const KeyFeatures = lazy(() => import('pages/home/components/KeyFeatures'));
const HowItWorks = lazy(() => import('pages/home/components/HowItWorks'));
const Testimonials = lazy(() => import('components/Testimonials'));
const DiveIntoSwimSync = lazy(() => import('pages/home/components/DiveIntoSwimSync'));
const Pricing = lazy(() => import('pages/home/components/Pricing'));
const FAQs = lazy(() => import('pages/home/components/FAQs'));
const News = lazy(() => import('pages/home/components/News'));
const Footer = lazy(() => import('components/footer'));



const Home = () => {
  return (
    <>
      <div className="app__wrapper">
        <img
          src="/images/header/background-image.png"
          alt=""
          className="app__wrapper--image"
        />
        <Header />
        <BookDemo />
      </div>
      <SocialProof />
      <KeyFeatures />
      <HowItWorks />
      <Testimonials />
      <DiveIntoSwimSync />
      <Pricing />
      <FAQs />
      <News />
      <Footer />
    </>
  );
};

export default Home;
