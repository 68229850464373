import "./desktop.scss";

const BookDemo = () => {
  return (
    <div className="FeatureMemberPortal-book-demo">
      <div className="FeatureMemberPortal-book-demo--wrapper">
        <div className="FeatureMemberPortal-book-demo--title">
          Ready to dive into a better swim school experience?
          <br />
          Join the Swim Sync family today!
        </div>
        <button
          className="FeatureMemberPortal-book-demo--button"
          onClick={() => {}}
        >
          Sign Up Now
        </button>
      </div>
    </div>
  );
};

export default BookDemo;
