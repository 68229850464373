import Slider from "react-slick";
import "./desktop.scss";
import SlideUpOnScroll from "components/SlideUpOnScroll";

const Introduction = () => {
  const settings = {
    centerMode: true,
    infinite: true,
    centerPadding: "10px",
    slidesToShow: 2.65,
    speed: 500,
    dots: false,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  const imageData: string[] = [
    "/images/introduction/image1.png",
    "/images/introduction/image2.png",
    "/images/introduction/image3.png",
    "/images/introduction/image4.png",
  ];

  const listFeatures1 = [
    {
      id: 1,
      title: "Student Management",
      description:
        "Effortlessly track student progress, manage enrollments, and access detailed profiles.",
      icon: "/svgs/introduction/student.svg",
    },
    {
      id: 2,
      title: "Class Scheduling",
      description:
        "Optimize your class timetables with intelligent scheduling and resource allocation.",
      icon: "/svgs/introduction/class.svg",
    },
    {
      id: 3,
      title: "Instructor Management",
      description:
        "Assign instructors, manage qualifications, and track performance seamlessly.",
      icon: "/svgs/introduction/instructor.svg",
    },
    {
      id: 4,
      title: "Financial Oversight",
      description:
        "Gain real-time insights into your financials, from class revenues to overall profitability.",
      icon: "/svgs/introduction/financial.svg",
    },
  ];
  const listFeatures2 = [
    {
      id: 5,
      title: "Multi-Location Support",
      description:
        "Manage multiple locations from a single, unified interface.",
      icon: "/svgs/introduction/location.svg",
    },
    {
      id: 6,
      title: "Communication Hub",
      description:
        "Streamline communication with staff, instructors, and parents all in one place.",
      icon: "/svgs/introduction/communication.svg",
    },
    {
      id: 7,
      title: "Resource Allocation",
      description:
        "Efficiently manage pool space, equipment, and other resources across your school.",
      icon: "/svgs/introduction/resource.svg",
    },
    {
      id: 8,
      title: "Customizable Reporting",
      description:
        "Generate insightful reports tailored to your specific needs and KPIs",
      icon: "/svgs/introduction/customize.svg",
    },
  ];
  return (
    <div className="FeatureStaffPortal-introduction">
      <SlideUpOnScroll>
        <div className="FeatureStaffPortal-introduction--header">
          <div className="FeatureStaffPortal-introduction--header--title">
            Introduction
          </div>
          <div className="FeatureStaffPortal-introduction--header--description">
            Dive into effortless swim school management with Swim Sync's
            Comprehensive Staff Portal. Designed to be your all-in-one command
            center, our Staff Portal empowers you to oversee every aspect of
            your swim school operations with ease and precision.
          </div>
        </div>
      </SlideUpOnScroll>
      <SlideUpOnScroll>
        <div className="FeatureStaffPortal-introduction--slider-wrapper">
          <Slider {...settings}>
            {imageData.map((item, key) => (
              <div
                key={key}
                className="FeatureStaffPortal-introduction--slider-wrapper--image"
              >
                <img src={item} alt="" />
              </div>
            ))}
          </Slider>
        </div>
      </SlideUpOnScroll>
      <SlideUpOnScroll>
        <div className="FeatureStaffPortal-introduction--features-wrapper">
          {listFeatures1.map((item, key) => (
            <div
              key={key}
              className="FeatureStaffPortal-introduction--features--item"
            >
              <img
                src={item.icon}
                alt=""
                className="FeatureStaffPortal-introduction--features--item--icon"
              />
              <div className="info">
                <div className="FeatureStaffPortal-introduction--features--item--title">
                  {item.title}
                </div>
                <div className="FeatureStaffPortal-introduction--features--item--description">
                  {item.description}
                </div>
              </div>
            </div>
          ))}
        </div>
      </SlideUpOnScroll>
      <SlideUpOnScroll>
        <div className="FeatureStaffPortal-introduction--features-wrapper">
          {listFeatures2.map((item, key) => (
            <div
              key={key}
              className="FeatureStaffPortal-introduction--features--item"
            >
              <img
                src={item.icon}
                alt=""
                className="FeatureStaffPortal-introduction--features--item--icon"
              />
              <div className="info">
                <div className="FeatureStaffPortal-introduction--features--item--title">
                  {item.title}
                </div>
                <div className="FeatureStaffPortal-introduction--features--item--description">
                  {item.description}
                </div>
              </div>
            </div>
          ))}
        </div>
      </SlideUpOnScroll>
    </div>
  );
};
export default Introduction;
