import Header from "components/header/Header";
import "./desktop.scss";
import Testimonials from "components/Testimonials";
import Introduction from "./components/Introduction";
import Footer from "components/footer";
import BookDemo from "./components/BookDemo";
import SlideUpOnScroll from "components/SlideUpOnScroll";

const FeatureStaffPortal = () => {
  return (
    <>
      <div className="app__wrapper">
        <img
          src="/images/header/bg-feature-header.png"
          alt=""
          className="app__wrapper--image"
        />
        <Header />
        <div className="FeatureStaffPortal-header-wrapper">
          <div className="FeatureStaffPortal-header-title">
            Comprehensive Staff Portal
          </div>
          <div className="FeatureStaffPortal-header-description">
            Your command center for swim school excellence
          </div>
        </div>
      </div>
      <Introduction />
      <Testimonials />
      <SlideUpOnScroll>
        <BookDemo />
      </SlideUpOnScroll>
      <Footer />
    </>
  );
};

export default FeatureStaffPortal;
