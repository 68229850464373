import FeatureStaffPortal from "pages/features/StaffPortal";
import FeatureMemberPortal from "pages/features/MemberPortal";
import { IRoute } from "../interfaces";
import Home from "pages/home";

export const publicIndexRoutes: IRoute[] = [
  {
    path: "/",
    component: <Home />,
    isPublic: true
  },
  {
    path: "*",
    component: <Home />,
    isPublic: true
  },
  {
    path: "/feature/staff",
    component: <FeatureStaffPortal />,
    isPublic: true
  },
  {
    path: "/feature/member",
    component: <FeatureMemberPortal />,
    isPublic: true
  }
];
