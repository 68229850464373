import { memo } from "react";

import "./desktop.scss";
import "./mobile.scss";

const Footer = () => {
  const swimSyncFooterLinks = [
    {
      title: "Explore",
      subLinks: [
        { label: "Features", link: "#", icon: null },
        { label: "Pricing", link: "#", icon: null },
        { label: "About Us", link: "#", icon: null },
        { label: "Contact", link: "#", icon: null },
        { label: "Support", link: "#", icon: null },
      ],
    },
    {
      title: "Follow",
      subLinks: [
        {
          label: "Facebook",
          link: "#",
          icon: <img src="/svgs/facebook.svg" alt="logo" />,
        },
        {
          label: "Instagram",
          link: "#",
          icon: <img src="/svgs/instagram.svg" alt="logo" />,
        },
      ],
    },
    {
      title: "Newsletter signup",
      subLinks: [
        {
          label: "Newsletter signup",
          link: "#",
          icon: null,
        },
        {
          label: (
            <div className="input__wrapper">
              <input type="text" placeholder="Email Address" />
              <button className="btn">Subscribe Now</button>
            </div>
          ),
          link: "#",
          icon: null,
        },
      ],
    },
  ];

  return (
    <>
      <div className="footer" id="contact">
        <div className="footer-content">
          <div className="logo">
            <img src="/svgs/logo-text-white.svg" alt="logo" />
          </div>

          <div className="linkWrap">
            {swimSyncFooterLinks.map((item, key) => (
              <div className="wrap" key={key}>
                <div className="tittle">{item?.title}</div>

                <div className="subWrap">
                  {item?.subLinks.map((subLink, key2) => (
                    <div className="wrap" key={key2}>
                      <div className="label">
                        {subLink?.icon ? subLink?.icon : null}
                        {subLink?.label}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="copyright">
          <div>Copyright © 2023. All Right Reserved</div>
          <div>Privacy Policy, Terms of Service</div>
        </div>
      </div>
    </>
  );
};
export default memo(Footer);
