import { memo, useEffect, useState } from "react";
import { HiXMark } from "react-icons/hi2";
import SideBarItem from "./SideBarItem";
import { HiMenu } from "react-icons/hi";

import "./desktop.scss";
import "./mobile.scss";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const [isShowSidebar, setIsShowSidebar] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 76) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleCollapse = (): void => {
    setIsShowSidebar((pre) => !pre);
  };

  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);

    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const menuData = [
    {
      title: "Feature",
      link: "feature",
      children: [
        {
          title: "Staff Portal",
          subTitle: "Streamline Operations",
          path: "/feature/staff",
          icon: "/svgs/header/staff.svg",
        },
        {
          title: "Member Portal",
          subTitle: "Engage Parents",
          path: "/feature/member",
          icon: "/svgs/header/member.svg",
        },
      ],
    },
    {
      title: "Pricing",
      link: "pricing",
    },
    {
      title: "Resources",
      link: "resources",
    },
    {
      title: "About",
      link: "about",
    },
    {
      title: "Contact",
      link: "contact",
    },
  ];

  return (
    <>
      <div className={`app-header ${isScrolled ? "scrolled" : ""}`}>
        <div className="app-header-wrapper">
          <HiMenu size={24} onClick={() => toggleCollapse()} />

          <img src={"/images/logo.png"} className="app-logo" alt="logo" onClick={() => navigate("/")} />
          <div className="menu-wrapper">
            {menuData.map((item, index) => (
              <div
                className="menu-item"
                key={index}
                onClick={() => scrollToSection(item.link)}
              >
                {item.title}
                {item.children && (
                  <div className="menu-item__sub">
                    {item.children.map((child, index) => (
                      <div
                        key={index}
                        className="menu-item__sub--item"
                        onClick={() => navigate(child.path)}
                      >
                        <img src={child.icon} alt="icon" />

                        <div className="info">
                          <div className="title">{child.title}</div>
                          <div className="subTitle">{child.subTitle}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="app-header-right">
            <div className="sign-up-wrapper">
              <div className="text">Sign Up</div>
            </div>
            <div className="login-wrapper">
              <div className="text">Log in</div>
            </div>
            <div className="book-demo-wrapper">
              <div
                className="text"
                onClick={() => {
                  // Mở widget khi nhấn nút
                  window.Calendly.showPopupWidget(
                    "https://calendly.com/swimsync-support/swim-sync-demo-booking"
                  );
                }}
              >
                Book a Demo
              </div>
            </div>
          </div>
        </div>
      </div>

      {isShowSidebar && (
        <div className="sidebar sidebar--open">
          <div className="sidebar__menu__list">
            <div className="sidebar__menu__list__icon sidebar__menu__list__icon--header">
              <img src="/svgs/logo-sign.svg" alt="logo" />
              <HiXMark
                size={22}
                style={{ cursor: "pointer" }}
                onClick={() => toggleCollapse()}
              />
            </div>
            <div className={`sidebar__menu__list__item`}>
              {menuData.map((item, index: number) => (
                <SideBarItem
                  key={index}
                  item={item}
                  isActive={false}
                  onClick={() => scrollToSection(item.link)}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default memo(Header);
