import React from "react";

import "./desktop.scss";
import SlideUpOnScroll from "components/SlideUpOnScroll";

const Testimonials = () => {
  const data = [
    {
      id: 1,
      message:
        "Swim Sync has transformed how we run our multi-location swim school. The comprehensive management tools have saved us countless hours and improved our service quality.",
      author: "Sarah Johnson",
      role: "SwimKids Academy Owner",
    },
    {
      id: 2,
      message:
        "Swim Sync has been a game-changer for our growing swim school network. The multi-location management feature has allowed us to expand seamlessly, while the robust reporting tools give us invaluable insights into our performance across all sites. It's like having an extra set of eyes underwater and above the surface!",
      author: "Michael Chen",
      role: "Director of AquaStars Swim Academy",
    },
    {
      id: 3,
      message:
        "As an instructor, I was skeptical about new tech, but Swim Sync won me over in a single lesson. The easy-to-use roll call system and real-time progress tracking have revolutionized how I manage my classes. Plus, the parents love the instant updates. It's like we've added a turbo boost to our swim lessons!",
      author: "Emma Rodriguez",
      role: "Head Instructor at Dolphin Bay Swim School",
    },
  ];
  return (
    <div className="testimonials">
      <div className="testimonials__container">
        <div className="testimonials__title">Testimonials</div>
        <div className="testimonials__content">
          {data.map((item) => {
            return (
              <SlideUpOnScroll key={item.id}>
                <div className="testimonials__content--item" key={item.id}>
                  <p className="testimonials__content--item--message">
                    {item.message}
                  </p>
                  <div className="testimonials__content--item--author">
                    <div className="testimonials__content--item--author--info">
                      <p className="testimonials__content--item--author--info--name">
                        {item.author}
                      </p>
                      <p className="testimonials__content--item--author--info--role">
                        {item.role}
                      </p>
                    </div>
                    <div className="quote">
                      <img src="/images/howItWorks/quote.png" alt="" />
                    </div>
                  </div>
                </div>
              </SlideUpOnScroll>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
