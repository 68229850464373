import Slider from "react-slick";
import SlideUpOnScroll from "components/SlideUpOnScroll";
import "./desktop.scss";

const Introduction = () => {
  const settings = {
    centerMode: true,
    infinite: true,
    centerPadding: "10px",
    slidesToShow: 2.65,
    speed: 500,
    dots: false,
    autoplay: true,
    autoplaySpeed: 3000
  };
  const imageData: string[] = [
    "/images/introductionMP/image5.png",
    "/images/introductionMP/image6.png",
    "/images/introductionMP/image7.png",
    "/images/introductionMP/image8.png"
  ];

  const listFeatures1 = [
    {
      id: 1,
      title: "Easy Enrollment",
      description:
        "Register for classes and manage your swim programs effortlessly.",
      icon: "/svgs/introduction/clipboard.svg"
    },
    {
      id: 2,
      title: "Schedule Management",
      description: "View and manage your class schedules with ease.",
      icon: "/svgs/introduction/calendar-swim.svg"
    },
    {
      id: 3,
      title: "Progress Tracking",
      description: "Monitor your child's skill development and achievements.",
      icon: "/svgs/introduction/chart-swim.svg"
    },
    {
      id: 4,
      title: "Seamless Communication",
      description:
        "Stay connected with instructors and receive important updates.",
      icon: "/svgs/introduction/messages.svg"
    },
    {
      id: 5,
      title: "Secure Payments",
      description: "Handle all financial transactions safely and conveniently.",
      icon: "/svgs/introduction/shield-dollar.svg"
    },
    {
      id: 6,
      title: "Resource Access",
      description: "Access valuable swimming resources and learning materials.",
      icon: "/svgs/introduction/block-waves.svg"
    }
  ];

  return (
    <div className="FeatureMemberPortal-introduction">
      <SlideUpOnScroll>
        <div className="FeatureMemberPortal-introduction--header">
          <div className="FeatureMemberPortal-introduction--header--title">
            Introduction
          </div>
          <div className="FeatureMemberPortal-introduction--header--description">
            Welcome to the Swim Sync Members Portal - your personal hub for a
            stress-free swim school experience. Designed with parents and
            students in mind, our Members Portal puts you in control of your
            swim journey, from enrollment to progress tracking.
          </div>
        </div>
      </SlideUpOnScroll>
      <SlideUpOnScroll>
        <div className="FeatureMemberPortal-introduction--slider-wrapper">
          <Slider {...settings}>
            {imageData.map((item, key) => (
              <div
                key={key}
                className="FeatureMemberPortal-introduction--slider-wrapper--image"
              >
                <img src={item} alt="" />
              </div>
            ))}
          </Slider>
        </div>
      </SlideUpOnScroll>
      <SlideUpOnScroll>
        <div className="FeatureMemberPortal-introduction--features-wrapper">
          {listFeatures1.map((item, key) => (
            <div
              key={key}
              className="FeatureMemberPortal-introduction--features--item"
            >
              <img
                src={item.icon}
                alt=""
                className="FeatureMemberPortal-introduction--features--item--icon"
              />
              <div className="info">
                <div className="FeatureMemberPortal-introduction--features--item--title">
                  {item.title}
                </div>
                <div className="FeatureMemberPortal-introduction--features--item--description">
                  {item.description}
                </div>
              </div>
            </div>
          ))}
        </div>
      </SlideUpOnScroll>
    </div>
  );
};
export default Introduction;
